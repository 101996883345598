import * as Yup from 'yup';
import { EMAIL_PATTERN, NAME_PATTERN } from '../../../../constants/AppConstants';
import { store } from '../../../../store/Store';

export const LABELS = {
  REFERENCES: 'References',
  REFERENCE: 'Reference ',
  EMAIL: 'Email *',
  NAME: 'Full name *',
  RELATIONSHIP: 'Relationship',
  MOBILE: 'Mobile',
  ADD_REFERENCE: 'Would you like to add a personal reference?',
  ADD_ANOTHER_REFERENCE: '+ Add another reference',
  ADD_ANOTHER_REFERENCE_DESCRIPTON:
    'A friend, colleague, associate or someone who know you well. Not a relative or partner.',
  BUTTON_TEXT: 'Save and next',
  NEXT: 'Save and next',
  LATER: "I'LL DO IT LATER",
  REMOVE: 'Remove',
  REFERENCE_ATTACHED: 'Reference attached',
  NO_RESPONSE_GIVEN: 'No response given',
  REFERENCE_ATTACHED_MESSAGE: 'Reference is attached to this personal record',
  NO_RESPONSE_GIVEN_MESSAGE: `Your reference did not respond. You might want to contact them 
    directly to ensure they are receiving the request.`,
  WARNING_TEXT: `Your reference will be contacted as a reference to confirm the details you have provided. 
    The reference will be linked to your application profile and used when submitting this and future applications. 
    You will receive confirmation when it is linked. Please note, only agencies you submit applications to will have 
    access to the responses provided by your referee.`,
};

export enum SubmissionStatus {
  SUBMITTED = 'submitted',
  PENDING = 'pending',
}

export const ERRORS = {
  required: 'Required',
  invalidNameFormat: 'Invalid name format',
  invalidEmailFormat: 'Invalid email format',
  maxStringLength: 'Can be at most 255 characters',
  requiredEmail: 'Please provide your email address',
  requiredName: 'Please provide name',
  selfReferenced: 'Cannot add yourself as a reference',
  requiredRelationship: 'Please provide the relationship',
  otherReferenced: 'You cannot add someone as a reference who you are also applying with',
};

const { tenantDetails } = store.getState().landingScreen;

export const VALIDATION_SCHEMA = Yup.object().shape({
  references: Yup.array().when('addReference', {
    addReference: Yup.boolean(),
    is: true,
    then: Yup.array().of(
      Yup.object().shape({
        relationship: Yup.mixed(),
        name: Yup.string()
          .max(255, ERRORS.maxStringLength)
          .matches(NAME_PATTERN, ERRORS.invalidNameFormat)
          .nullable(),
        email: Yup.string()
          .max(255, ERRORS.maxStringLength)
          .matches(EMAIL_PATTERN, ERRORS.invalidEmailFormat)
          .test('value', ERRORS.selfReferenced, (value) => {
            if (tenantDetails) {
              return value !== tenantDetails.email;
            }
            return true;
          })
          .test('value', ERRORS.otherReferenced, (value) => {
            let result = true;
            if (tenantDetails) {
              tenantDetails.otherApplicants.map((other) => {
                result = value !== other.email;
              });
            }
            return result;
          })
          .nullable(),
      }),
    ),
  }),
});
