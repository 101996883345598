import ApiService from '../ApiService';
import { ApplyPropertyForm } from '../dashboard/postApplyForm/PostApplyForm.data';
import { useFetchQuery, useMutationQuery } from '../reactQuery/reactQuery';
import { ServiceType } from '../ServiceType.data';

const service = new ApiService(ServiceType.Draft);

export const useGetDraftById = (id?: string) => {
  const { data, isFetching } = useFetchQuery<ApplyPropertyForm, {}>(
    ['draftApplication', { id }],
    () => service.get({ route: [id!] }),
    {
      enabled: Boolean(id), // Query only runs if `id` exists
    },
  );

  return {
    draftApplication: data,
    fetchingDraftApplication: isFetching,
  };
};

export const usePutDraft = () => {
  const { mutate, isLoading } = useMutationQuery({
    key: ['updateDraft'],
    func: (params: ApplyPropertyForm) => {
      const { lastModifiedDate, ...body } = params;
      return service.put({ route: [params.draftId!] }, body);
    },
  });

  return {
    updateDraft: mutate,
    updatingDraft: isLoading,
  };
};
