import { QUERY_KEYS } from '../reactQuery/keys';
import { useFetchQuery } from '../reactQuery/reactQuery';
import { ServiceType } from '../ServiceType.data';
import TenancyApiService from '../TenancyApiService';
import { BranchConfig } from './branchConfigType';

const service = new TenancyApiService(ServiceType.BranchConfig);

export const useGetBranchConfig = (code: string) => {
  const { data, isLoading, refetch } = useFetchQuery<BranchConfig, string>(
    QUERY_KEYS.branchConfig(code),
    () => {
      return service.get({ route: [code], query: { configNameList: 'customProfileConfig' } }, true);
    },
    { enabled: !!code },
  );

  return { branchConfig: data, loadingBranchConfig: isLoading, getBranchConfig: refetch };
};
