/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import React, { FC, useState, useEffect, useRef } from 'react';
import { Typography, Input, FormControl } from '@mui/material';
import { Formik, FormikValues } from 'formik';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';
import { useStyles } from './DeclarationFormStyles';
import { LABELS } from './DeclarationFormConstants';
import { PROFILE_FORMS as ProfileForm } from '../../../../constants/AppConstants';
import {
  PostDeclarationFormData,
  PostPersonalDetailsFormData,
  DigitalSignature,
  PostFormData,
} from '../../../../store/state/MyProfileFormState';

import { CustomTermsHeaderResponse } from '../../../../store/actions/CustomTermsHeaderActions';
import { ApplicationState } from '../../../../store/RootReducers';
import { MasterProfileActions } from '../../../../store/actions/MasterProfileActions';
import {
  SignDataPayload,
  SubmittedApplicationResponse,
} from '../../../../services/dashboard/postApplyForm/PostApplyForm.data';
import ScrollBox from './components/ScrollBox';
import { AdditionalPersonalDetailsScreenActions } from '../../../../store/actions/AdditionalPersonalDetailsAction';
import { MyProfileAction } from '../../../../store/actions/MyProfileFormActions';
import Button from '../../../../component/button/Button';

interface DeclarationFormProps {
  declarationDetails: PostDeclarationFormData | undefined;
  index: number | undefined;
  refId: string | null | undefined;
  personalDetails: PostPersonalDetailsFormData | undefined;
  headerDetails: CustomTermsHeaderResponse | null | undefined;
  myAccountFlag: boolean;
  masterProfileData: SubmittedApplicationResponse | null;
  setSelected: (nextSection: ProfileForm | null) => void;
  setChildFormRef: (ref: Formik<FormikValues> | null) => void;
  postDeclarationFormDetails: (data: PostFormData, onSubmit: () => void, id?: number) => void;
  postSaveSignature: (data: SignDataPayload, success: () => void) => void;
  updateProfileChangesFlag: (isChanged: boolean) => void;
}

const DeclarationForm: FC<DeclarationFormProps> = ({
  declarationDetails,
  index,
  refId,
  personalDetails,
  headerDetails,
  myAccountFlag,
  masterProfileData,
  setSelected,
  setChildFormRef,
  postDeclarationFormDetails,
  postSaveSignature,
  updateProfileChangesFlag,
}) => {
  const classes = useStyles();
  const formRef = useRef<Formik<PostDeclarationFormData> | null>(null);
  const [signatureUpdated, setSignatureUpdated] = useState<boolean>(false);

  useEffect(() => {
    setChildFormRef(formRef.current);
  }, []);

  const updateSignature = (
    setFieldValue: (fieldName: string, fieldValue: DigitalSignature) => void,
    digitalSignature: DigitalSignature,
  ) => {
    setFieldValue(`digitalSignature`, digitalSignature);
    setSignatureUpdated(true);
  };

  const initialValues: PostDeclarationFormData = declarationDetails || {
    isSortedTermsAccepted: false,
    digitalSignature: undefined,
  };

  return (
    <Formik
      ref={formRef}
      initialValues={initialValues}
      onSubmit={(formData) => {
        updateProfileChangesFlag(true);
        if (signatureUpdated && formData.digitalSignature) {
          const data = {
            masterProfileData,
            personalDetails,
            refId,
            digitalSignature: formData.digitalSignature,
          };
          postSaveSignature(data, () => {
            setSignatureUpdated(true);
            formData.isSortedTermsAccepted = true;
            postDeclarationFormDetails(
              formData,
              () => {
                setSelected(null);
              },
              index,
            );
          });
        } else {
          formData.isSortedTermsAccepted = true;
          postDeclarationFormDetails(
            formData,
            () => {
              setSelected(null);
            },
            index,
          );
        }
      }}
      validateOnChange
      render={({ values, handleSubmit, setFieldValue }) => (
        <div className={classes.formStyle}>
          <Typography variant="h5" className={classes.titleStyle}>
            {LABELS.DECLARATION}
          </Typography>

          <ScrollBox headerDetails={headerDetails} />

          <Typography className={classes.agreeOnSignStyle}>{LABELS.AGREE_ON_SIGN}</Typography>
          <Typography className={classes.confirmMessageStyle}>
            {LABELS.CONFIRMATION_MESSGE}
          </Typography>

          <div className={classes.signingContainer}>
            <FormControl className={classes.inputContainerStyle}>
              <Typography className={classes.signingLabel} variant="subtitle1">
                {LABELS.SIGN_HERE}
              </Typography>
              <Input
                className={classes.inputStyle}
                name="digitalSignature"
                placeholder="Type here..."
                type="text"
                disableUnderline
                onChange={(event) => updateSignature(setFieldValue, event.target.value)}
                value={values.digitalSignature}
              />
            </FormControl>
          </div>

          <Button onPress={handleSubmit} parentStyles={classes.buttonStyles}>
            {myAccountFlag ? LABELS.ACCEPT : LABELS.I_AGREE}
          </Button>
        </div>
      )}
    />
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  headerDetails: state.termsHeader.termsHeaderDetails,
  myAccountFlag: state.myProfileForm.myAccountFlag,
  masterProfileData: state.masterProfile.masterProfileData!,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  postSaveSignature: (data: SignDataPayload, success: () => void) => {
    dispatch(MasterProfileActions.postSaveSignature(data, success));
  },
  updateProfileChangesFlag: (isChanged: boolean) => {
    dispatch(MasterProfileActions.updateProfileChangesFlag(isChanged));
  },
  postDeclarationFormDetails: (data: PostFormData, onSubmit: () => void, id?: number) => {
    id !== undefined
      ? dispatch(AdditionalPersonalDetailsScreenActions.postDeclarationFormDetails(data, id))
      : dispatch(MyProfileAction.postDeclarationFormDetails(data));
    onSubmit();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeclarationForm);
