import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import {
  ApplicationFormStep,
  ApplyPropertyFormPayload,
  BulkApplyPropertyFormPayload,
  DeleteDraftRequest,
  GetApplicationsPayload,
  MasterProfileResponsePayload,
  PatchTitlePayload,
  ReferencesPayload,
  RefIdPairs,
  SignDataPayload,
} from './postApplyForm/PostApplyForm.data';
import { getRefId } from '../../helper/PostHelper';
import { ApplicationsResponse } from '../groups/Groups';
import { DeletedData } from '../../models/deletedData/DeletedData';

const serviceType = ServiceType.Apply;
const serviceTypeCreate = ServiceType.Draft;
const masterProfile = ServiceType.MasterProfile;
const serviceSaveReferencesProfile = ServiceType.SaveReferencesProfile;
const signatureApi = ServiceType.SignatureApi;
const userDelete = ServiceType.User;

export class ApplyService {
  private readonly service: ApiServiceBase = new ApiService(serviceType);
  private readonly serviceCreate: ApiServiceBase = new ApiService(serviceTypeCreate);
  private readonly masterProfileService: ApiServiceBase = new ApiService(masterProfile);
  private readonly serviceSaveReferencesProfile: ApiServiceBase = new ApiService(
    serviceSaveReferencesProfile,
  );
  private readonly signatureApiService: ApiServiceBase = new ApiService(signatureApi);
  private readonly userApiService: ApiServiceBase = new ApiService(userDelete);

  public getDeletedUserInfo(): Promise<DeletedData[]> | DeletedData[] {
    return this.userApiService.get({
      route: ['deleted-data'],
    });
  }

  public dismissDeletedUserInfo(): Promise<void> | void {
    return this.userApiService.post(
      {
        route: ['dismiss', 'deleted-data'],
      },
      {},
    );
  }

  // FOR SUBMITTING NEW APPLICATION
  public postPropertyApplyForm(
    payload: ApplyPropertyFormPayload,
  ): Promise<RefIdPairs> | RefIdPairs {
    return this.service.post(
      {
        route: ['submit'],
      },
      payload.applyForm,
    );
  }

  // FOR UPDATING AN APPLICATION
  // Deprecated
  public postUpdatePropertyApplyForm(
    payload: ApplyPropertyFormPayload,
    applicationRefId: string,
    propertyRefId: string,
  ): Promise<RefIdPairs> | RefIdPairs {
    return this.service.post(
      {
        route: ['update', 'application', applicationRefId, 'property', propertyRefId],
      },
      payload.applyForm,
    );
  }

  public postApplyDraft(payload: ApplyPropertyFormPayload): Promise<void> | void {
    const { draftId } = payload.applyForm;
    let route: string[] = [];
    if (draftId) {
      route = ['draft', draftId];
    }
    if (draftId) {
      return this.service.put({ route }, payload.applyForm);
    }
    return this.serviceCreate.post(
      { route },
      {
        ...payload.applyForm,
        applicationRequest: {
          ...payload.applyForm.applicationRequest,
          currentStep: ApplicationFormStep.APPLICATION,
        },
      },
    );
  }

  public deleteApplyDraft(
    payload: DeleteDraftRequest,
  ): Promise<ApplicationsResponse> | ApplicationsResponse {
    return this.service.delete({
      route: ['draft', payload.draftId],
    });
  }

  public getApplications(payload: GetApplicationsPayload): Promise<void> | void {
    return this.service.get({
      route: ['user', payload.userId],
    });
  }

  public patchDraftTitle(payload: PatchTitlePayload): Promise<void> | void {
    const { draftId, title, userId } = payload;
    if (draftId && userId) {
      return this.service.patch(
        {
          route: ['user', userId, 'draft', draftId],
          query: { title: title || 'My Application' },
        },
        {},
      );
    }
  }

  public getMasterProfileData(): Promise<void> | void {
    return this.masterProfileService.get({});
  }

  public postSaveReferencesProfile(payload: ReferencesPayload[]): Promise<void> | void {
    return this.serviceSaveReferencesProfile.post({}, payload);
  }

  public getLatestSubmittedApplication(): Promise<void> | void {
    const userId = localStorage.getItem('userId');
    return this.service.get({
      route: ['user', userId || '', 'lastApplication'],
    });
  }

  public postSaveMasterProfile(payload: ApplyPropertyFormPayload): Promise<void> | void {
    return this.masterProfileService.post<any>({}, payload.applyForm);
  }

  public postSaveSignature(
    data: SignDataPayload,
  ): Promise<MasterProfileResponsePayload> | MasterProfileResponsePayload {
    const { masterProfileData, personalDetails, refId, digitalSignature } = data;
    const referenceId = getRefId(masterProfileData, personalDetails, refId);
    const payload = { refId: referenceId, digitalSignature };
    return this.signatureApiService.post({}, payload);
  }

  public getLatestMasterProfileVersion(userId: string) {
    return this.masterProfileService.get({
      route: ['version', 'user', userId],
    });
  }

  // FOR THE UPDATE-ALL APPLICATIONS FEATURE
  public postUpdatedPropertiesToTenancy(
    payload: BulkApplyPropertyFormPayload,
  ): Promise<RefIdPairs> | RefIdPairs {
    return this.service.post(
      {
        route: ['submit', 'bulk', 'applications'],
      },
      payload,
    );
  }
}

const propertyService = new ApplyService();
export default propertyService;
