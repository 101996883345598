/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { takeEvery, put, all, call, fork } from 'redux-saga/effects';
import { MyProfileAction } from '../actions/MyProfileFormActions';
import {
  DashboardAction,
  DashboardActions,
  DashboardActionTypes,
} from '../actions/DashboardActions';

import { ApplicationState } from '../RootReducers';
import {
  convertDataToPost,
  convertPropertyDataToPost,
  convertReferencesBody,
  mapRefIdsToProfiles,
  mapApplyReferenceIdsToProfiles,
} from '../../helper/PostHelper';
import { LoadingIndicatorActions } from '../actions/LoadingIndicatorAction';
import {
  PatchTitlePayload,
  GetApplicationsPayload,
  MasterProfileResponsePayload,
  MasterProfilePayload,
  ApplicantResponsePayload,
  SignDataPayload,
  BulkApplyPropertyFormPayload,
  BulkUpdateRequest,
  DeleteDraftRequest,
} from '../../services/dashboard/postApplyForm/PostApplyForm.data';
import ApplyService from '../../services/dashboard/ApplyService';
import { SuccessToastActions } from '../actions/ SuccessToastAction';
import {
  MasterProfileActions,
  MasterProfileActionTypes,
  MasterProfileAction,
} from '../actions/MasterProfileActions';
import { Logging } from '../../helper/Logging';
import localStorage from '../../services/LocalStorage';
import {
  convertAddressToAddressFormData,
  convertEmploymentToEmploymentFormData,
  convertReferenceToReferenceFormData,
} from '../../helper/PrefillFormHelper';
import { AdditionalPersonalDetailsScreenActions } from '../actions/AdditionalPersonalDetailsAction';
import { GroupsActions } from '../actions/GroupsActions';
import { DeletedData } from '../../models/deletedData/DeletedData';

// FOR SUBMITTING A NEW APPLICATION
// postPropertyApplyForm
export function* postApplicationToPropertiesWorker(action: DashboardAction) {
  try {
    yield put(LoadingIndicatorActions.show(true));
    const data = action.data as ApplicationState;
    // TODO unsure on type
    const masterProfileVersionNumber: boolean = yield ApplyService.getLatestMasterProfileVersion(
      localStorage.getItem('userId')!,
    );
    const refIdMappedData = mapApplyReferenceIdsToProfiles(data);
    const convertedData = convertPropertyDataToPost(refIdMappedData, masterProfileVersionNumber);
    const response: unknown = yield ApplyService.getApplications({
      userId: localStorage.getItem('userId')!,
    });
    yield put(DashboardActions.getApplicationsSuccess(response));
    if (data.dashboard.applications.submittedApplications.length === 0) {
      yield ApplyService.postPropertyApplyForm(convertedData);
      yield put(DashboardActions.postPropertyApplySuccess());
      localStorage.setItem('draftId', '');
      localStorage.setItem('draftTitle', '');
      localStorage.setItem('state', '');
      localStorage.setItem('stateCreatedDate', '');
    } else {
      yield ApplyService.postPropertyApplyForm(convertedData);
      yield put(DashboardActions.postPropertyApplySuccess());
      localStorage.setItem('draftId', '');
      localStorage.setItem('draftTitle', '');
      localStorage.setItem('state', '');
      localStorage.setItem('stateCreatedDate', '');
    }
  } catch (error) {
    Logging.error('ApplySaga.postApplicationToPropertiesWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(DashboardActions.postPropertyApplyFailure(error as Error));
  } finally {
    yield put(LoadingIndicatorActions.hide(true));
  }
}

// FOR UPDATING AN APPLICATION
// postUpdatePropertyApplyForm
// Depreciated
export function* postUpdateApplicationToPropertiesWorker(action: DashboardAction) {
  try {
    yield put(LoadingIndicatorActions.show(true));
    const data = action.data as ApplicationState;
    // TODO unsure on type
    const masterProfileVersionNumber: boolean = yield ApplyService.getLatestMasterProfileVersion(
      localStorage.getItem('userId')!,
    );
    const refIdMappedData = mapApplyReferenceIdsToProfiles(data);
    const convertedData = convertPropertyDataToPost(refIdMappedData, masterProfileVersionNumber);
    const { applicationRefId, propertyRefId } = data.dashboard;
    if (applicationRefId && propertyRefId) {
      yield ApplyService.postUpdatePropertyApplyForm(
        convertedData,
        applicationRefId,
        propertyRefId,
      );
    }
    if (data.dashboard.applications.submittedApplications.length === 0) {
      yield put(DashboardActions.postUpdatePropertyApplySuccess());
      localStorage.setItem('draftId', '');
      localStorage.setItem('draftTitle', '');
      localStorage.setItem('state', '');
      localStorage.setItem('stateCreatedDate', '');
    } else {
      yield put(DashboardActions.postPropertyApplySuccess());
      localStorage.setItem('draftId', '');
      localStorage.setItem('draftTitle', '');
      localStorage.setItem('state', '');
      localStorage.setItem('stateCreatedDate', '');
    }
  } catch (error) {
    Logging.error('ApplySaga.postUpdateApplicationToPropertiesWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(DashboardActions.postUpdatePropertyApplyFailure(error as Error));
  } finally {
    yield put(LoadingIndicatorActions.hide(true));
  }
}

// FOR SENDING UPDATES TO ALREADY SUBMITTED APPLICATIONS
export function* postUpdatedPropertiesToTenancyWorker(action: DashboardAction) {
  try {
    yield put(LoadingIndicatorActions.show(true));
    const data = action.data as BulkUpdateRequest;
    const { applicationState, refIdPairs } = data;
    // TODO unsure on type
    const masterProfileVersionNumber: boolean = yield ApplyService.getLatestMasterProfileVersion(
      localStorage.getItem('userId')!,
    );
    const refIdMappedData = mapApplyReferenceIdsToProfiles(applicationState);
    const convertedData = convertPropertyDataToPost(refIdMappedData, masterProfileVersionNumber);
    yield ApplyService.postUpdatedPropertiesToTenancy({
      applyForm: convertedData.applyForm,
      refIdPairs,
    } as BulkApplyPropertyFormPayload);
    yield put(DashboardActions.postApplySuccess());
    localStorage.setItem('draftId', '');
    localStorage.setItem('draftTitle', '');
    localStorage.setItem('state', '');
    localStorage.setItem('stateCreatedDate', '');
  } catch (error) {
    Logging.error('ApplySaga.postUpdateApplicationToPropertiesWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(DashboardActions.postUpdatePropertyApplyFailure(error as Error));
  } finally {
    yield put(LoadingIndicatorActions.hide(true));
  }
}

export function* postApplicationDraftWorker(action: DashboardAction) {
  try {
    const data = action.data as ApplicationState;
    const convertedData = convertPropertyDataToPost(data, true);
    // TODO unsure on type
    const applyDraftResponse: {
      draftId: string;
      title: string;
    } = yield ApplyService.postApplyDraft(convertedData);
    if (!localStorage.getItem('draftId')) {
      if (applyDraftResponse && applyDraftResponse.draftId) {
        localStorage.setItem('draftId', applyDraftResponse.draftId.toString());
        localStorage.setItem('draftTitle', applyDraftResponse.title.toString());
      }
    }
    if (data.isSaveAsDraftBtnClicked) {
      yield put(LoadingIndicatorActions.show());
      yield put(DashboardActions.postApplyDraftSuccess());
      localStorage.setItem('draftId', '');
      localStorage.setItem('draftTitle', '');
      localStorage.setItem('state', '');
      localStorage.setItem('stateCreatedDate', '');
    } else {
      yield put(GroupsActions.getAllApplicationsRequest());
      yield put(SuccessToastActions.show());
    }

    if (data.onSuccess) {
      data.onSuccess();
    }
  } catch (error) {
    Logging.error('ApplySaga.postApplicationDraftWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(DashboardActions.postApplyDraftFailure(error as Error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* getApplicationDraftWorker(action: DashboardAction) {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as GetApplicationsPayload;
    const response: unknown = yield ApplyService.getApplications(data);
    yield put(DashboardActions.getApplicationsSuccess(response));
  } catch (error) {
    Logging.error('ApplySaga.getApplicationDraftWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(DashboardActions.getApplicationsError(error as Error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* patchTitle(action: DashboardAction) {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as PatchTitlePayload;
    yield ApplyService.patchDraftTitle(data);
    yield put(DashboardActions.patchDraftTitleSuccess({}));
  } catch (error) {
    Logging.error('ApplySaga.patchTitle', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(DashboardActions.patchDraftTitleError(error as Error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* postSaveReferencesProfileWorker(action: MasterProfileAction) {
  try {
    yield put(LoadingIndicatorActions.show(true));
    const data = action.data as ApplicationState;
    const convertedData = convertReferencesBody(data);
    const postMasterProfileRes: MasterProfileResponsePayload =
      yield ApplyService.postSaveReferencesProfile(convertedData);
    yield put(SuccessToastActions.show('Your reference has been saved!'));

    if (!postMasterProfileRes.primary.profileData.firstName) {
      postMasterProfileRes.primary.profileData.email = localStorage.getItem('email') || '';
      postMasterProfileRes.primary.profileData.firstName = localStorage.getItem('firstname') || '';
      postMasterProfileRes.primary.profileData.lastName = localStorage.getItem('lastname') || '';
    }

    /** Asign  signatureLink to every applicant */
    const primaryApplicant = postMasterProfileRes.primary.profileData;
    const additionalApplicants = postMasterProfileRes.secondary;

    /** Updating State(myProfileForm) for Primary Applicant */
    if (primaryApplicant.applicantAddressHistory) {
      yield put(
        MyProfileAction.postAddressFormDetails({
          addresses: primaryApplicant.applicantAddressHistory.map((address) =>
            convertAddressToAddressFormData(address),
          ),
        }),
      );
    }

    if (primaryApplicant.applicantEmploymentHistory) {
      yield put(
        MyProfileAction.postEmploymentFormDetails({
          hasBeenEmployed: primaryApplicant.hasBeenEmployed,
          employments: primaryApplicant.applicantEmploymentHistory.map((employment) =>
            convertEmploymentToEmploymentFormData(employment),
          ),
        }),
      );
    }

    if (primaryApplicant.applicantReference) {
      yield put(
        MyProfileAction.postReferencesFormDetails({
          references: primaryApplicant.applicantReference.map((reference) =>
            convertReferenceToReferenceFormData(reference),
          ),
          addReference: !!primaryApplicant.applicantReference.length,
        }),
      );
    }

    /** Updating State(additionalPersonalDetailScreen.secondaryApplicantProfiles) for Secondary Applicants */
    let index = 0;
    for (const applicant of additionalApplicants) {
      if (applicant.profileData.applicantAddressHistory) {
        yield put(
          AdditionalPersonalDetailsScreenActions.postAddressFormDetails(
            {
              addresses: applicant.profileData.applicantAddressHistory.map((address) =>
                convertAddressToAddressFormData(address),
              ),
            },
            index,
            applicant.profileData.refId,
          ),
        );
      }
      index++;
    }

    index = 0;
    for (const applicant of additionalApplicants) {
      if (applicant.profileData.applicantEmploymentHistory) {
        yield put(
          AdditionalPersonalDetailsScreenActions.postEmploymentFormDetails(
            {
              hasBeenEmployed: applicant.profileData.hasBeenEmployed,
              employments: applicant.profileData.applicantEmploymentHistory.map((employment) =>
                convertEmploymentToEmploymentFormData(employment),
              ),
            },
            index,
            applicant.profileData.refId,
          ),
        );
      }
      index++;
    }

    index = 0;
    for (const applicant of additionalApplicants) {
      if (applicant.profileData.applicantReference) {
        yield put(
          AdditionalPersonalDetailsScreenActions.postReferencesFormDetails(
            {
              references: applicant.profileData.applicantReference.map((reference) =>
                convertReferenceToReferenceFormData(reference),
              ),
              addReference: !!applicant.profileData.applicantReference.length,
            },
            index,
            applicant.profileData.refId,
          ),
        );
      }
      index++;
    }

    /** Assign  signatureLink to every applicant */
    const resData: MasterProfilePayload = {
      applicants: [postMasterProfileRes.primary.profileData],
    };

    let key = 0;
    if (postMasterProfileRes.secondary.length > 0) {
      postMasterProfileRes.secondary.forEach((element: ApplicantResponsePayload) => {
        element.profileData.key = key;
        resData.applicants.push(element.profileData);
        key++;
      });
    }
    if (postMasterProfileRes.dependents.length > 0) {
      postMasterProfileRes.dependents.forEach((element: ApplicantResponsePayload) => {
        element.profileData.key = key;
        resData.applicants.push(element.profileData);
        key++;
      });
    }
    yield put(MasterProfileActions.setMasterProfile(resData));
    if (data.onSuccess) {
      data.onSuccess();
    }
  } catch (error) {
    Logging.error('ApplySaga.postSaveReferencesProfileWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    SuccessToastActions.showError('Error: Something went wrong!');
  } finally {
    yield put(LoadingIndicatorActions.hide(true));
  }
}

export function* postSaveMasterProfileWorker(action: MasterProfileAction) {
  try {
    yield put(LoadingIndicatorActions.show(true));
    const data = action.data as ApplicationState;
    const refIdMappedData = mapRefIdsToProfiles(data);
    const convertedData = convertPropertyDataToPost(refIdMappedData, true);
    const postMasterProfileRes: MasterProfileResponsePayload =
      yield ApplyService.postSaveMasterProfile(convertedData);

    yield put(SuccessToastActions.show('Your profile has been saved!'));

    if (!postMasterProfileRes.primary.profileData.firstName) {
      postMasterProfileRes.primary.profileData.email = localStorage.getItem('email') || '';
      postMasterProfileRes.primary.profileData.firstName = localStorage.getItem('firstname') || '';
      postMasterProfileRes.primary.profileData.lastName = localStorage.getItem('lastname') || '';
    }

    const resData: MasterProfilePayload = {
      applicants: [postMasterProfileRes.primary.profileData],
    };
    let key = 0;
    if (postMasterProfileRes.secondary.length > 0) {
      for (const element of postMasterProfileRes.secondary) {
        element.profileData.key = key;

        yield put(
          AdditionalPersonalDetailsScreenActions.saveRefIdForAdditionalProfile(
            element.profileData.refId as string,
            key + 1,
          ),
        );
        resData.applicants.push(element.profileData);
        key++;
      }
    }
    if (postMasterProfileRes.dependents.length > 0) {
      for (const element of postMasterProfileRes.dependents) {
        element.profileData.key = key;

        resData.applicants.push(element.profileData);
        key++;
      }
    }
    yield put(MasterProfileActions.setMasterProfile(resData));
  } catch (error) {
    Logging.error('ApplySaga.postSaveMasterProfileWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    SuccessToastActions.showError('Error: Something went wrong!');
  } finally {
    yield put(LoadingIndicatorActions.hide(true));
  }
}

export function* postSaveSignatureWorker(action: MasterProfileAction) {
  try {
    yield put(LoadingIndicatorActions.show(true));
    const data = action.data as SignDataPayload;
    let postMasterProfileResponse: MasterProfileResponsePayload =
      yield ApplyService.postSaveSignature(data);
    if (!postMasterProfileResponse.primary.profileData.firstName) {
      postMasterProfileResponse.primary.profileData.email = localStorage.getItem('email') || '';
      postMasterProfileResponse.primary.profileData.firstName =
        localStorage.getItem('firstname') || '';
      postMasterProfileResponse.primary.profileData.lastName =
        localStorage.getItem('lastname') || '';
    }
    yield put(SuccessToastActions.show('Signature updated!'));

    const resData: MasterProfilePayload = {
      applicants: [postMasterProfileResponse.primary.profileData],
    };

    let key = 0;
    if (postMasterProfileResponse.secondary.length > 0) {
      postMasterProfileResponse.secondary.forEach((element: ApplicantResponsePayload) => {
        element.profileData.key = key;
        resData.applicants.push(element.profileData);
        key++;
      });
    }
    if (postMasterProfileResponse.dependents.length > 0) {
      postMasterProfileResponse.dependents.forEach((element: ApplicantResponsePayload) => {
        element.profileData.key = key;
        resData.applicants.push(element.profileData);
        key++;
      });
    }
    yield put(MasterProfileActions.setMasterProfile(resData));
    if (data.success) {
      data.success();
    }
  } catch (error) {
    Logging.error('ApplySaga.postSaveSignatureWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(MasterProfileActions.saveSignatureError(error as Error));
  } finally {
    yield put(LoadingIndicatorActions.hide(true));
  }
}

export function* getDeletedData() {
  try {
    const response: DeletedData[] = yield ApplyService.getDeletedUserInfo();
    if (response.length) {
      yield put(DashboardActions.getDeletedInfoSuccess(true));
    }
  } catch (error) {
    yield put(DashboardActions.getDeletedInfoError(error as Error));
  }
}

export function* dismissDeletedData() {
  try {
    yield ApplyService.dismissDeletedUserInfo();
    yield put(DashboardActions.dismissDeletedInfoSuccess(false));
  } catch (error) {
    yield put(DashboardActions.dismissDeletedInfoError(error as Error));
  }
}

// Need to add the functionality for an update-submit action
function* applyWatcher() {
  yield takeEvery(DashboardActionTypes.POST_PROPERTY_APPLY_REQUEST, (action: DashboardAction) =>
    postApplicationToPropertiesWorker({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(DashboardActionTypes.POST_APPLY_DRAFT, (action: DashboardAction) =>
    postApplicationDraftWorker({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(DashboardActionTypes.GET_APPLICATIONS_REQUSET, (action: DashboardAction) =>
    getApplicationDraftWorker({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(DashboardActionTypes.PATCH_DRAFT_TITLE_REQUEST, (action: DashboardAction) =>
    patchTitle({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(
    MasterProfileActionTypes.POST_SAVE_REFERENCES_PROFILE,
    (action: MasterProfileAction) =>
      postSaveReferencesProfileWorker({
        type: action.type,
        data: action.data,
      }),
  );
  yield takeEvery(
    MasterProfileActionTypes.POST_SAVE_MASTER_PROFILE,
    (action: MasterProfileAction) =>
      postSaveMasterProfileWorker({
        type: action.type,
        data: action.data,
      }),
  );
  yield takeEvery(MasterProfileActionTypes.POST_SAVE_SIGNATURE, (action: MasterProfileAction) =>
    postSaveSignatureWorker({
      type: action.type,
      data: action.data,
    }),
  );
  // Depreicated
  yield takeEvery(
    DashboardActionTypes.POST_UPDATE_PROPERTY_APPLY_REQUEST,
    (action: DashboardAction) =>
      postUpdateApplicationToPropertiesWorker({
        type: action.type,
        data: action.data,
      }),
  );
  yield takeEvery(
    DashboardActionTypes.POST_UPDATED_PROPERTIES_TO_TENANCY_REQUEST,
    (action: DashboardAction) =>
      postUpdatedPropertiesToTenancyWorker({
        type: action.type,
        data: action.data,
      }),
  );
  yield takeEvery(DashboardActionTypes.GET_DELETED_INFO_REQUEST, (action: DashboardAction) =>
    getDeletedData(),
  );
  yield takeEvery(DashboardActionTypes.DISMISS_DELETED_INFO_REQUEST, (action: DashboardAction) =>
    dismissDeletedData(),
  );
}

export default applyWatcher;
