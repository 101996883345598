/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useEffect, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Paper,
  Typography,
} from '@mui/material';
import { ErrorMessage, Formik } from 'formik';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import errorIcon from '../../../../assets/error-outline-24-px.svg';
import sortedIcon from '../../../../assets/sorted-icon-dark.png';
import visibilityOn from '../../../../assets/outline-remove-red-eye-24-px.svg';
import visibilityOff from '../../../../assets/outline-visibility-off-24-px.svg';
import localStorage from '../../../../services/LocalStorage';
import { RegisterClientResponse, RegisterUserPayload } from '../../../../services/login/LoginData';
import UserConfirmationDialog from '../../../../component/userConfirmationDialog/UserConfirmationDialog';
import { LoginActions } from '../../../../store/actions/LoginActions';
import { ApplicationState } from '../../../../store/RootReducers';
import { View } from '../../Login';
import { LABELS, VALIDATION_SCHEMA } from './SignupFormConstants';
import { useStyles } from './SignupFormStyles';
import { theme } from '../../../../theme/Theme';
import Input from '../../../../component/input/Input';
import Text from '../../../../component/text/Text';
import Box from '../../../../component/box/Box';
import Button from '../../../../component/button/Button';
import { WHY_SORTED } from '../loginForm/LoginFormConstants';
import { emailTransform, nameTransform } from '../../../../helper/FieldValueTransformationHelper';

interface SignupFormProps {
  registerClientDetails: RegisterClientResponse | undefined;
  registerError: any;
  postRegister: (data: RegisterUserPayload, onSubmit: () => void) => void;
  setView: (view: View) => void;
  setUserDetails: (userDetails: RegisterUserPayload) => void;
  userDetails: RegisterUserPayload | null;
}

const SignupForm: FC<SignupFormProps> = ({
  registerClientDetails,
  registerError,
  postRegister,
  setView,
  setUserDetails,
  userDetails,
}) => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [confirmDialogTitle, setConfirmDialogTitle] = useState<string>('');
  const [confirmDialogContent, setConfirmDialogContent] = useState<JSX.Element>(<></>);

  useEffect(() => {
    // TODO move out to higher up auth handler
    if (registerClientDetails) {
      const { client_id, client_secret } = registerClientDetails;
      localStorage.setItem('clientId', client_id);
      localStorage.setItem('clientSecret', client_secret);
      const basicAuthHash = window.btoa(`${client_id}:${client_secret}`);
      localStorage.setItem('authTokenBasic', basicAuthHash);
    }

    if (registerError && registerError.response && registerError.response.status === 400) {
      setErrorMessage('User already exists. Please use the sorted credentials to login');
    }
  }, [registerError, registerClientDetails]);

  const postRegisterDetails = (data: RegisterUserPayload) => {
    postRegister(data, () => {
      setUserDetails({ ...data });
      setView(View.Verify);
    });
  };

  const showQuestionPopup = () => {
    setConfirmDialogTitle('Create your profile to get started');
    setConfirmDialogContent(
      <div>
        Your Sorted Applications profile lets you apply for properties in minutes - anytime, from
        any device. Enter your details to get started (we hate spam even more than you do, so we
        promise we&apos;ll never do it).
      </div>,
    );
    setOpenConfirmDialog(true);
  };

  return (
    <>
      <div className={classes.titleContainer}>
        <img src={sortedIcon} className={classes.icon} />
        <div className={classes.primaryTitle}>{LABELS.CREATE_ACCOUNT}</div>
      </div>
      <div className={classes.pageContent}>
        <div className={classes.primaryContainer}>
          <Formik
            initialValues={{
              firstname: userDetails && userDetails.firstname ? userDetails.firstname : '',
              lastname: userDetails && userDetails.lastname ? userDetails.lastname : '',
              email: userDetails && userDetails.email ? userDetails.email : '',
              password: '',
              redirectionParams: `redirectLink=${window.location.toString().split('#/')[1] || ''}`,
            }}
            validateOnChange
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={(data) => {
              if (!submitted) {
                setSubmitted(true);
                postRegisterDetails(data);
                setTimeout(() => {
                  setSubmitted(false);
                }, 2000);
              }
            }}
            render={({ values, handleSubmit, setFieldValue, setFieldTouched, errors, touched }) => (
              <form onSubmit={handleSubmit}>
                <Text textVariant="boxTitle">{LABELS.SIGNUP_TITLE}</Text>
                <Box>
                  <div className={classes.subtitleRow}>
                    <Text textVariant="info">{LABELS.SIGNUP_SUBTITLE}</Text>
                    <HelpOutlineIcon
                      className={classes.questionMark}
                      onMouseDown={() => showQuestionPopup()}
                    />
                  </div>
                  {/* FIRSTNAME ----------------------------------------------------------------- */}
                  <div className={classes.inputRow}>
                    <Input
                      placeholder={LABELS.FIRST_NAME}
                      key={LABELS.FIRST_NAME}
                      value={values.firstname}
                      setValue={(value: string) => setFieldValue('firstname', value)}
                      onBlur={() => {
                        setFieldTouched('firstname');
                        setFieldValue('firstname', nameTransform(values.firstname));
                      }}
                      touched={touched.firstname}
                      error={errors.firstname}
                      title={LABELS.FIRST_NAME}
                      mandatory
                    />

                    {/* LASTNAME ----------------------------------------------------------------- */}
                    <Input
                      placeholder={LABELS.LAST_NAME}
                      key={LABELS.LAST_NAME}
                      value={values.lastname}
                      setValue={(value: string) => setFieldValue('lastname', value)}
                      onBlur={() => {
                        setFieldTouched('lastname');
                        setFieldValue('lastname', nameTransform(values.lastname));
                      }}
                      touched={touched.lastname}
                      error={errors.lastname}
                      title={LABELS.LAST_NAME}
                      parentStyles={classes.secondInput}
                      mandatory
                    />
                  </div>

                  {/* EMAIL ----------------------------------------------------------------- */}
                  <Input
                    placeholder={LABELS.EMAIL_ADDRESS}
                    key={LABELS.EMAIL_ADDRESS}
                    value={values.email}
                    setValue={(value: string) => {
                      setFieldValue('email', value);
                    }}
                    onBlur={() => {
                      setFieldTouched('email');
                      setFieldValue('email', emailTransform(values.email));
                    }}
                    touched={touched.email}
                    error={errors.email || errorMessage}
                    title={LABELS.EMAIL_ADDRESS}
                    mandatory
                  />

                  {/* PASSWORD ----------------------------------------------------------------- */}

                  <Input
                    placeholder={LABELS.PASSWORD}
                    key={LABELS.PASSWORD}
                    value={values.password}
                    setValue={(value: string) => setFieldValue('password', value)}
                    onBlur={() => setFieldTouched('password')}
                    touched={touched.password}
                    error={errors.password}
                    title={LABELS.PASSWORD}
                    password={!showPassword}
                    mandatory
                    endElement={
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        <img
                          src={showPassword ? visibilityOff : visibilityOn}
                          alt=""
                          style={{ height: 20, width: 20 }}
                        />
                      </IconButton>
                    }
                  />

                  <ul className={classes.list}>
                    <li className={classes.dotPoint}>At least 8 characters</li>
                    <li className={classes.dotPoint} style={{ marginTop: '5px' }}>
                      One special character
                    </li>
                    <li className={classes.dotPoint} style={{ marginTop: '5px' }}>
                      One uppercase character
                    </li>
                  </ul>

                  <div className={classes.signupRow}>
                    <Text textVariant="link">{LABELS.ALREADY_HAVE_ACCOUNT}</Text>
                    <Button
                      outline
                      parentStyles={classes.loginButton}
                      onPress={() => setView(View.Login)}
                    >
                      {LABELS.LOGIN_BUTTON}
                    </Button>
                    <Button
                      parentStyles={classes.signupButton}
                      type="submit"
                      onPress={() => handleSubmit()}
                    >
                      {LABELS.SIGNUP_BUTTON}
                    </Button>
                  </div>
                </Box>
              </form>
            )}
          />
        </div>
        <div className={classes.secondaryContainer}>
          <Text textVariant="boxTitle">{LABELS.WHY_SORTED}</Text>
          {WHY_SORTED.map((w, idx) => (
            <div className={classes.welcomeBanner} key={idx}>
              <div className={classes.welcomeLeftContent}>
                <Text textVariant="contentTitle">{w.title}</Text>
                <Text textVariant="info" parentStyles={classes.welcomeSubtitle}>
                  {w.body}
                </Text>
              </div>
              <div className={classes.welcomeImageBackground}>
                <img src={w.background} className={classes.welcomeLeftImage} alt="overlay" />
                <img src={w.image} className={classes.welcomeRightImage} alt="welcome" />
              </div>
            </div>
          ))}
        </div>
      </div>
      <UserConfirmationDialog
        isOpen={openConfirmDialog}
        title={confirmDialogTitle}
        content={confirmDialogContent}
        primaryButtonTitle="Okay"
        onPrimaryClick={() => setOpenConfirmDialog(false)}
        onClose={() => setOpenConfirmDialog(false)}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  postRegister: (data: RegisterUserPayload, onSuccess: () => void) => {
    dispatch(LoginActions.postRegisterUserStart({ ...data, onSuccess }));
  },
});

const mapStateToProps = (state: ApplicationState) => ({
  registerError: state.login.registerError,
  registerClientDetails: state.login.registerClientDetails,
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
