/* eslint-disable import/prefer-default-export */

import { maintenancePagesURLIdentifier } from './constants/AppConstants';

export const routes = {
  applicationPreferences: {
    // TODO: delete
    new: '/applicationPreferences',
    view: `/applicationPreferences/:refId`,
  },
  application: {
    new: '/application',
  },
  groups: {
    view: '/groups',
    edit: '/groups/edit',
    application: '/application/group/update',
  },
  submitProperties: {
    new: '/submitProperties',
  },
  dependentProfiles: {
    view: (index: string | number) => `/dependentProfiles/${index.toString()}`,
  },
  myProfile: {
    new: '/my-profile',
    view: (formName: string) => `/my-profile/${formName}`,
  },
  applicationMyProfile: {
    new: '/application/profile',
    view: (formName: string) => `/application/profile/${formName}`,
  },
  additionalApplicant: {
    new: (index: number | string) => `/additional-profile/${index.toString()}`,
    view: (index: number | string, formName: string) =>
      `/additional-profile/${index.toString()}/${formName}`,
  },
  introduction: {
    new: '/introduction',
  },
  success: {
    new: '/success',
  },
  error: {
    new: '/404',
  },
  properties: {
    view: (agencyCode: string, branchCode?: string) => {
      if (branchCode && branchCode !== 'undefined') {
        return `/properties/${agencyCode}/${branchCode}`;
      }
      return `/properties/${agencyCode}`;
    },
    generic: '/properties',
  },
  advancedSearch: {
    view: (agencyCode: string, branchCode?: string) => {
      if (branchCode && branchCode !== 'undefined') {
        return `/advanced-search/${agencyCode}/${branchCode}`;
      }
      return `/advanced-search/${agencyCode}`;
    },
    generic: '/advanced-search',
  },
  viewProperty: {
    view: (refId: string) => `/property/${refId}`,
  },
  login: {
    view: '/login',
  },
  resetPassword: {
    view: '/reset-password',
  },
  applications: {
    view: '/applications',
  },
  home: {
    view: '/home',
  },
  registrationConfirmation: {
    new: '/register-confirmation',
  },
  resetPasswordConfirmation: {
    new: '/reset-password-confirmation',
  },
  welcomeReferee: {
    view: '/reference-tool/:tokenId',
  },
  questionnaire: {
    view: '/questionnaire',
  },
  thankyouScreen: {
    view: '/thankyou-screen',
  },
  referenceToolError: {
    view: '/error-screen',
  },
  tenantMaintenanceRequestForm: {
    view: `/${maintenancePagesURLIdentifier}/:agency`,
  },
  remoteSigning: {
    view: (token: string) => `/signing/${token}`,
    viewApply: (token: string) => `/signing/${token}?source=apply`,
  },
  disclosureSigning: {
    view: (token: string) => `/disclosure/${token}`,
  },
  shortApplication: {
    view: (agencyCode: string, branchCode?: string) => {
      if (branchCode && branchCode !== 'undefined') {
        return `/shortApplication/${agencyCode}/${branchCode}`;
      }
      return `/shortApplication/${agencyCode}`;
    },
    referral: (agencyCode: string) => `/shortApplication/referral/${agencyCode}`,
  },
  residentRequest: {
    view: `/residentRequests`,
  },
  additionalDocuments: {
    path: '/additionalDocuments',
    view: '/additionalDocuments/:refId',
  },
};
