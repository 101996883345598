import { lowerCase } from 'lodash';
import { titleCase } from '../container/tenantMR/components/SelectProperty/SelectProperty';

export const nameTransform = (name?: string | null) => {
  if (!name) {
    return name;
  } else {
    return titleCase(name);
  }
};

export const emailTransform = (email?: string | null) => {
  if (!email) {
    return email;
  } else {
    return email.toLowerCase();
  }
};
