/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useState, useEffect, useRef, useMemo } from 'react';
import { Formik, FormikValues } from 'formik';
import { History } from 'history';
import { cloneDeep, head, lowerCase } from 'lodash';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useStyles } from './PersonalDetailsFormStyles';
import { LABELS, ERRORS, VALIDATION_SCHEMA } from './PersonalDetailsFormConstants';
import FooterComponent from '../../../../component/footer/Footer';
import { PROFILE_FORMS as ProfileForm, SALUTATIONS } from '../../../../constants/AppConstants';
import {
  PostPersonalDetailsFormData,
  EmergencyContactRelationship,
} from '../../../../store/state/MyProfileFormState';
import { TypeHelper } from '../../../../helper/TypeHelper';
import {
  OtherApplicantsData,
  OverviewFormData as OverviewDetails,
} from '../../../../services/landingScreen/OverviewForm.data';
import ToggleButton from '../../../../component/toggleButton/ToggleButton';
import {
  QuestionType,
  ProfileQuestion,
  Config,
} from '../../../../store/actions/CustomTermsHeaderActions';
import CustomButton from '../../../../component/customButton/CustomButton';
import { formatQuestion } from '../../../../helper/PrefillFormHelper';
import 'react-phone-number-input/style.css';
import { MasterProfileActions } from '../../../../store/actions/MasterProfileActions';
import localStorage from '../../../../services/LocalStorage';
import { AdditionalPersonalDetailsScreenActions } from '../../../../store/actions/AdditionalPersonalDetailsAction';
import { MyProfileAction } from '../../../../store/actions/MyProfileFormActions';
import { ApplicationState } from '../../../../store/RootReducers';
import { AdditionalPersonalDetailsScreenState } from '../../../../store/state/AdditionalPersonalDetailsScreenState';
import { LandingScreenActions } from '../../../../store/actions/LandingScreenAction';
import { CalculationHelper } from '../../../../helper/CalculationHelper';
import { routes } from '../../../../Routes';
import InputSelect from '../../../../component/inputSelect/InputSelect';
import Input from '../../../../component/input/Input';
import InputDate from '../../../../component/inputDate/InputDate';
import InputMobile from '../../../../component/inputMobile/InputMobile';
import Text from '../../../../component/text/Text';
import Button from '../../../../component/button/Button';
import { isEmpty } from '../../../../helper/Validation';
import moment from 'moment';
import { titleCase } from '../../../tenantMR/components/SelectProperty/SelectProperty';
import { emailTransform, nameTransform } from '../../../../helper/FieldValueTransformationHelper';

interface PersonalDetailsFormProps {
  history: History;
  personalDetails: PostPersonalDetailsFormData | undefined;
  overviewDetails: OverviewDetails;
  tenantDetails: OverviewDetails | undefined;
  otherDetails: AdditionalPersonalDetailsScreenState;
  index: number | undefined;
  isAbove18: boolean;
  myAccountFlag: boolean;
  additionalProfileEmails: string[];
  setIsChildAgeAbove18: (date: any) => void;
  updateProfileChangesFlag: (isChanged: boolean) => void;
  postFormDetails: (
    data: PostPersonalDetailsFormData,
    tenantDetails: OverviewDetails | undefined,
    otherDetails: AdditionalPersonalDetailsScreenState,
    onSubmit: () => void,
    id?: number,
  ) => void;
  setSelected: (nextSection: ProfileForm | null) => void;
  setChildFormRef: (ref: Formik<FormikValues> | null) => void;
}

const PersonalDetailsForm: FC<PersonalDetailsFormProps> = ({
  history,
  personalDetails,
  overviewDetails,
  tenantDetails,
  otherDetails,
  index,
  isAbove18,
  myAccountFlag,
  additionalProfileEmails,
  setIsChildAgeAbove18,
  updateProfileChangesFlag,
  postFormDetails,
  setSelected,
  setChildFormRef,
}) => {
  const classes = useStyles();
  const formRef = useRef<Formik<PostPersonalDetailsFormData> | null>(null);
  const config: Config = JSON.parse(localStorage.getItem('config')!) as Config;
  const configQuestions = config && config.questions ? config.questions : null;

  const [mobileNumber, setMobileNumber] = useState<string>(
    personalDetails ? personalDetails.mobile : '',
  );
  const [isValidNumber, setIsValidNumber] = useState<boolean>(true);
  const [guarantorContactNumber, setGuarantorContactNumber] = useState<string>(
    personalDetails && personalDetails.guarantorContactNumber
      ? personalDetails.guarantorContactNumber
      : '',
  );
  const [isValidGuarantorNumber, setIsValidGuarantorNumber] = useState<boolean>(true);
  const [duplicateEmail, setDuplicateEmail] = useState<boolean>(false);
  const [emergencyContactMobile, setEmergencyContactMobile] = useState<string>(
    personalDetails ? personalDetails.emergencyContactMobile : '',
  );
  const [isValidEmergencyContact, setIsValidEmergencyContact] = useState<boolean>(true);
  const [profileChanged, setProfileChanged] = useState<boolean>(false);

  const hasVisited =
    typeof personalDetails !== 'undefined' &&
    (!isEmpty(personalDetails.selectedDateOfBirth) ||
      !isEmpty(personalDetails.title) ||
      !isEmpty(personalDetails.middleName) ||
      !isEmpty(personalDetails.mobile) ||
      !isEmpty(personalDetails.emergencyContactName) ||
      !isEmpty(personalDetails.emergencyContactRelationship) ||
      !isEmpty(personalDetails.emergencyContactMobile) ||
      !isEmpty(personalDetails.emergencyContactEmail));

  const updateProfileState = () => {
    const userId = localStorage.getItem('userId');
    if (userId && !profileChanged) {
      updateProfileChangesFlag(true);
      setProfileChanged(true);
    }
  };

  const isDuplicateEmail = (email: string) =>
    [...additionalProfileEmails, localStorage.getItem('email')!].includes(email.trim());

  const filterProfileQuestions = (
    profileQuestions: ProfileQuestion[] | null | undefined,
  ): ProfileQuestion[] => {
    if (!profileQuestions && !configQuestions) {
      return [];
    }
    if (!profileQuestions) {
      return configQuestions || [];
    }
    if (!configQuestions) {
      return profileQuestions;
    }
    const questions: ProfileQuestion[] = [];
    configQuestions.map((question: ProfileQuestion) => {
      const checkQuestion = head(
        profileQuestions.filter(
          (profileQuestion: ProfileQuestion) =>
            formatQuestion(profileQuestion.question) === formatQuestion(question.question),
        ),
      );
      if (checkQuestion) {
        questions.push(checkQuestion);
      } else {
        questions.push(question);
      }
    });
    return questions;
  };

  const toggleHasGuarantor = (
    setFieldValue: (fieldName: string, fieldValue: string | boolean | null) => void,
    hasGuarantor: boolean | null,
  ) => {
    setFieldValue('hasGuarantor', !hasGuarantor);
    updateProfileState();
    if (hasGuarantor) {
      setFieldValue('guarantorName', '');
      setFieldValue('guarantorEmail', '');
      setFieldValue('guarantorContactNumber', '');
      setGuarantorContactNumber('');
      setIsValidGuarantorNumber(true);
    }
  };

  const initialValues = useMemo<PostPersonalDetailsFormData>(() => {
    let result: PostPersonalDetailsFormData = {
      email:
        typeof index === 'undefined' && localStorage.getItem('email')
          ? localStorage.getItem('email')
          : '',
      title: '',
      firstName:
        typeof index === 'undefined' && localStorage.getItem('firstname')
          ? localStorage.getItem('firstname')
          : '',
      lastName:
        typeof index === 'undefined' && localStorage.getItem('lastname')
          ? localStorage.getItem('lastname')
          : '',
      middleName: '',
      mobile: '',
      selectedDateOfBirth: undefined,
      hasGuarantor: false,
      guarantorName: '',
      guarantorContactNumber: '',
      guarantorEmail: '',
      introText: '',
      questions: configQuestions || null,
      emergencyContactName: '',
      emergencyContactEmail: '',
      emergencyContactMobile: '',
      emergencyContactRelationship: '',
    };

    if (typeof personalDetails !== 'undefined' && !TypeHelper.isEmptyObject(personalDetails)) {
      const questions = filterProfileQuestions(personalDetails.questions);
      result = {
        ...personalDetails,
        questions,
      };
    }

    // If personal details is not entered yet,
    // initialize using data from overview details
    if (typeof index === 'undefined' && !result.email && overviewDetails) {
      result.email = overviewDetails.email;
      result.title = overviewDetails.title;
      result.firstName = overviewDetails.firstName;
      result.lastName = overviewDetails.lastName;
    }

    // Check whether any fields of guarantor are present, otherwise
    // set the initial values to empty so that validations work
    if (!result.guarantorName && result.hasGuarantor === undefined) {
      result.hasGuarantor = false;
      result.guarantorName = '';
      result.guarantorContactNumber = '';
      result.guarantorEmail = '';
    }

    return result;
  }, []);

  useEffect(() => {
    setChildFormRef(formRef.current);
  }, []);

  return (
    <div className={classes.root}>
      <Formik
        ref={formRef}
        initialValues={initialValues}
        onSubmit={(onSubmitdata) => {
          if (
            isValidNumber &&
            isValidGuarantorNumber &&
            isValidEmergencyContact &&
            !duplicateEmail
          ) {
            const toSubmit = {
              ...onSubmitdata,
              mobile: mobileNumber,
              guarantorContactNumber,
              emergencyContactMobile,
            };
            updateProfileState();
            postFormDetails(
              toSubmit,
              tenantDetails,
              otherDetails,
              () => {
                if (isAbove18 || CalculationHelper.isAbove18(onSubmitdata.selectedDateOfBirth)) {
                  setSelected(ProfileForm.INTRODUCTION);
                } else {
                  history.goBack();
                }
              },
              index,
            );
          }
        }}
        validateOnBlur
        validateOnChange
        validationSchema={VALIDATION_SCHEMA}
        render={({ values, handleSubmit, setFieldValue, setFieldTouched, errors, touched }) => (
          <div className={classes.formStyle}>
            <div className={classes.inputRow}>
              {/* TITLE ----------------------------------------------------------------------- // */}
              <InputSelect
                placeholder={LABELS.SALUTATION}
                value={values.title || ''}
                values={SALUTATIONS.map((v) => ({ display: v, value: v }))}
                setValue={(value: string) => {
                  setFieldValue(`title`, value);
                  updateProfileState();
                }}
                onBlur={() => setFieldTouched('title')}
                touched={touched.title}
                error={errors.title}
                title={LABELS.SALUTATION}
                mandatory
                showRequiredError={(!!errors.title && !!touched.title) || hasVisited}
              />

              {/* FIRST NAME ----------------------------------------------------------------------- // */}
              <Input
                placeholder={LABELS.FIRSTNAME}
                value={values.firstName || ''}
                setValue={(value: string) => {
                  setFieldValue(`firstName`, value);
                  updateProfileState();
                }}
                onBlur={() => {
                  setFieldTouched('firstName');
                  setFieldValue(`firstName`, nameTransform(values.firstName));
                }}
                touched={touched.firstName}
                error={errors.firstName}
                title={LABELS.FIRSTNAME}
                mandatory
                showRequiredError={(!!errors.firstName && !!touched.firstName) || hasVisited}
                parentStyles={classes.secondInput}
              />
            </div>
            <div className={classes.inputRow}>
              {/* MIDDLENAME ----------------------------------------------------------------------- // */}
              <Input
                placeholder={LABELS.MIDDLENAME}
                value={values.middleName || ''}
                setValue={(value: string) => {
                  setFieldValue(`middleName`, value);
                  updateProfileState();
                }}
                onBlur={() => {
                  setFieldTouched('middleName');
                  setFieldValue(`middleName`, nameTransform(values.middleName));
                }}
                touched={touched.middleName}
                error={errors.middleName}
                title={LABELS.MIDDLENAME}
              />
              {/* SURNAME ----------------------------------------------------------------------- // */}
              <Input
                placeholder={LABELS.SURNAME}
                value={values.lastName || ''}
                setValue={(value: string) => {
                  setFieldValue(`lastName`, value);
                  updateProfileState();
                }}
                onBlur={() => {
                  setFieldTouched('lastName');
                  if (values.lastName) {
                    setFieldValue(`lastName`, nameTransform(values.lastName));
                  }
                }}
                touched={touched.lastName}
                error={errors.lastName}
                title={LABELS.SURNAME}
                mandatory
                showRequiredError={(!!errors.lastName && !!touched.lastName) || hasVisited}
                parentStyles={classes.secondInput}
              />
            </div>

            {/* DATE OF BIRTH ----------------------------------------------------------------------- // */}
            <InputDate
              placeholder={LABELS.DATE_OF_BIRTH}
              title={LABELS.DATE_OF_BIRTH}
              touched={touched.selectedDateOfBirth}
              error={errors.selectedDateOfBirth}
              value={values.selectedDateOfBirth}
              showRequiredError={
                (!!errors.selectedDateOfBirth && !!touched.selectedDateOfBirth) || hasVisited
              }
              setValue={(newValue: string | Date) => {
                setIsChildAgeAbove18(newValue);
                setFieldValue('selectedDateOfBirth', newValue);
                updateProfileState();
              }}
              maxDate={
                index === undefined
                  ? moment().subtract(18, 'years').format('YYYY-MM-DD')
                  : new Date()
              }
              mandatory
              onBlur={() => setFieldTouched('selectedDateOfBirth')}
            />
            {!isAbove18 && (
              <div className={classes.infoBox}>
                {values.selectedDateOfBirth === null && LABELS.DEPENDENT_INFO1}
                {LABELS.DEPENDENT_INFO2}
              </div>
            )}
            {isAbove18 && (
              <>
                {/* EMAIL ----------------------------------------------------------------------- // */}

                <Input
                  placeholder={LABELS.EMAIL_ADDRESS}
                  value={values.email || ''}
                  setValue={(value: string) => {
                    setFieldValue('email', value);
                    updateProfileState();
                    if (isDuplicateEmail(value)) {
                      setDuplicateEmail(true);
                    } else {
                      setDuplicateEmail(false);
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched('email');
                    setFieldValue(`email`, emailTransform(values.email));
                  }}
                  touched={touched.email}
                  error={errors.email}
                  title={LABELS.EMAIL_ADDRESS}
                  mandatory
                  showRequiredError={(!!errors.email && !!touched.email) || hasVisited}
                  disabled={
                    typeof index === 'undefined'
                      ? true
                      : personalDetails
                      ? !!personalDetails.email
                      : false
                  }
                />

                {/* MOBILE ----------------------------------------------------------------------- // */}
                <InputMobile
                  placeholder={LABELS.MOBILE}
                  value={values.mobile || ''}
                  setValue={(value: string) => {
                    const isValidNum = !!(value && TypeHelper.validatePhone(value || ''));
                    setFieldValue('mobile', value || '');
                    setIsValidNumber(isValidNum);
                    setMobileNumber(value || '');
                    updateProfileState();
                  }}
                  onBlur={() => setFieldTouched(`mobile`)}
                  touched={touched.mobile}
                  error={
                    !isValidNumber && values.mobile ? ERRORS.invalidCountryNumber : errors.mobile
                  }
                  title={LABELS.MOBILE}
                  mandatory
                  showRequiredError={(touched.mobile || hasVisited) && isEmpty(values.mobile)}
                />

                {/* EMERGENCY CONTACT ----------------------------------------------------------------------- // */}
                <div className={classes.emergencyContactCardStyle}>
                  <Text textVariant="boxTitle">{LABELS.EMERGENCY_CONTACT}</Text>
                  <Input
                    placeholder={LABELS.EMERGENCY_CONTACT_NAME}
                    value={values.emergencyContactName || ''}
                    setValue={(value: string) => {
                      setFieldValue(`emergencyContactName`, value);
                      updateProfileState();
                    }}
                    onBlur={() => {
                      setFieldTouched('emergencyContactName');
                      setFieldValue(
                        `emergencyContactName`,
                        nameTransform(values.emergencyContactName),
                      );
                    }}
                    touched={touched.emergencyContactName}
                    error={errors.emergencyContactName}
                    title={LABELS.EMERGENCY_CONTACT_NAME}
                    mandatory
                    showRequiredError={
                      (!!errors.emergencyContactName && !!touched.emergencyContactName) ||
                      hasVisited
                    }
                  />

                  {/* EMERGENCY RELATIONSHIP ----------------------------------------------------------------------- // */}
                  <InputSelect
                    placeholder={LABELS.EMERGENCY_CONTACT_RELATIONSHIP}
                    value={values.emergencyContactRelationship || ''}
                    values={Object.values(EmergencyContactRelationship).map((v) => ({
                      display: v,
                      value: v,
                    }))}
                    setValue={(value: string) => {
                      setFieldValue(`emergencyContactRelationship`, value);
                      updateProfileState();
                    }}
                    onBlur={() => setFieldTouched('emergencyContactRelationship')}
                    touched={touched.emergencyContactRelationship}
                    error={errors.emergencyContactRelationship}
                    title={LABELS.EMERGENCY_CONTACT_RELATIONSHIP}
                    mandatory
                    showRequiredError={
                      (!!errors.emergencyContactRelationship &&
                        !!touched.emergencyContactRelationship) ||
                      hasVisited
                    }
                  />

                  {/* EMERGENCY MOBILE ----------------------------------------------------------------------- // */}
                  <InputMobile
                    placeholder={LABELS.MOBILE}
                    value={values.emergencyContactMobile || ''}
                    setValue={(value: string) => {
                      const isValidEmergencyContactNum = !!(
                        value && TypeHelper.validatePhone(value || '')
                      );
                      setFieldValue('emergencyContactMobile', value);
                      setIsValidEmergencyContact(isValidEmergencyContactNum);
                      setEmergencyContactMobile(value || '');
                      updateProfileState();
                    }}
                    onBlur={() => setFieldTouched(`emergencyContactMobile`)}
                    touched={touched.emergencyContactMobile}
                    error={
                      !isValidEmergencyContact && values.emergencyContactMobile
                        ? ERRORS.invalidCountryNumber
                        : errors.emergencyContactMobile
                    }
                    title={LABELS.MOBILE}
                    mandatory
                    showRequiredError={
                      (touched.emergencyContactMobile || hasVisited) &&
                      isEmpty(values.emergencyContactMobile)
                    }
                  />

                  {/* EMERGENCY EMAIL ----------------------------------------------------------------------- // */}
                  <Input
                    placeholder={LABELS.EMAIL_ADDRESS}
                    value={values.emergencyContactEmail || ''}
                    setValue={(value: string) => {
                      setFieldValue(`emergencyContactEmail`, value);
                      updateProfileState();
                    }}
                    onBlur={() => {
                      setFieldTouched('emergencyContactEmail');
                      setFieldValue(
                        'emergencyContactEmail',
                        emailTransform(values.emergencyContactEmail),
                      );
                    }}
                    touched={touched.emergencyContactEmail}
                    error={errors.emergencyContactEmail}
                    title={LABELS.EMAIL_ADDRESS}
                    mandatory
                    showRequiredError={
                      (!!errors.emergencyContactEmail && !!touched.emergencyContactEmail) ||
                      hasVisited
                    }
                  />
                </div>
                <br />
                {/* GUARANTOR QUESTION ------------------------------------------------------------------ // */}
                <div className={classes.questionContainer}>
                  <Text>{LABELS.DO_YOU_HAVE_A_GUARANTOR}</Text>
                  <ToggleButton
                    toggleFunction={() => toggleHasGuarantor(setFieldValue, values.hasGuarantor)}
                    buttonState={values.hasGuarantor}
                  />
                </div>

                {/* GUARANTOR NAME ------------------------------------------------------------------ // */}
                {values.hasGuarantor && (
                  <>
                    <Input
                      placeholder={LABELS.GUARANTOR_NAME}
                      value={values.guarantorName || ''}
                      setValue={(value: string) => {
                        setFieldValue(`guarantorName`, value);
                        updateProfileState();
                      }}
                      onBlur={() => {
                        setFieldTouched('guarantorName');
                        setFieldValue(`guarantorName`, nameTransform(values.guarantorName));
                      }}
                      touched={touched.guarantorName}
                      error={errors.guarantorName}
                      title={LABELS.GUARANTOR_NAME}
                      mandatory
                      showRequiredError={
                        (!!errors.guarantorName && !!touched.guarantorName) || hasVisited
                      }
                    />

                    {/* GUARANTOR CONTACT ------------------------------------------------------------------ // */}
                    <InputMobile
                      placeholder={LABELS.MOBILE}
                      value={values.guarantorContactNumber || ''}
                      setValue={(value: string) => {
                        const isValidGuarantorNum = !!(
                          value && TypeHelper.validatePhone(value || '')
                        );
                        setFieldValue('guarantorContactNumber', value || '');
                        setIsValidGuarantorNumber(isValidGuarantorNum);
                        setGuarantorContactNumber(value || '');
                        updateProfileState();
                      }}
                      onBlur={() => setFieldTouched(`guarantorContactNumber`)}
                      touched={touched.guarantorContactNumber}
                      error={
                        !isValidGuarantorNumber && values.guarantorContactNumber
                          ? ERRORS.invalidCountryNumber
                          : errors.guarantorContactNumber
                      }
                      title={LABELS.MOBILE}
                      mandatory
                      showRequiredError={
                        (touched.guarantorContactNumber || hasVisited) &&
                        isEmpty(values.guarantorContactNumber)
                      }
                    />

                    {/* GUARANTOR EMAIL ------------------------------------------------------------------ // */}
                    <Input
                      placeholder={LABELS.EMAIL_ADDRESS}
                      value={values.guarantorEmail || ''}
                      setValue={(value: string) => {
                        setFieldValue(`guarantorEmail`, value);
                        updateProfileState();
                      }}
                      onBlur={() => {
                        setFieldTouched('guarantorEmail');
                        setFieldValue(`guarantorEmail`, emailTransform(values.guarantorEmail));
                      }}
                      touched={touched.guarantorEmail}
                      error={errors.guarantorEmail}
                      title={LABELS.EMAIL_ADDRESS}
                      mandatory
                      showRequiredError={
                        (!!errors.guarantorEmail && !!touched.guarantorEmail) || hasVisited
                      }
                    />
                  </>
                )}

                {/* QUESTIONS ------------------------------------------------------------------ // */}
                <div className={classes.questionsSection}>
                  {values.questions &&
                    values.questions.map((question: ProfileQuestion, questionIndex: number) => (
                      <>
                        {question.type === QuestionType.BUTTON ? (
                          <div className={classes.agencyQuestion}>
                            <Text>{question.question}</Text>

                            <CustomButton
                              onConfirmation={() => {
                                const questions = cloneDeep(values.questions!);
                                questions[questionIndex] = {
                                  ...question,
                                  answer: 'TRUE',
                                };
                                setFieldValue('questions', questions);
                              }}
                              onRejection={() => {
                                const questions = cloneDeep(values.questions!);
                                questions[questionIndex] = {
                                  ...question,
                                  answer: 'FALSE',
                                };
                                setFieldValue('questions', questions);
                              }}
                              active={
                                values.questions![questionIndex].answer !== null
                                  ? values.questions![questionIndex].answer === 'TRUE'
                                  : null
                              }
                            />
                          </div>
                        ) : (
                          <div className={classes.textQuestionContainer}>
                            <Text>{question.question}</Text>

                            <div className={classes.textInputContainer} style={{ marginTop: 15 }}>
                              <Input
                                placeholder="Answer"
                                value={values.questions![questionIndex].answer || ''}
                                setValue={(value: string) => {
                                  const questions = cloneDeep(values.questions!);
                                  questions[questionIndex] = {
                                    ...question,
                                    answer: value,
                                  };
                                  setFieldValue('questions', questions);
                                  updateProfileState();
                                }}
                                title="Answer"
                              />
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                </div>
              </>
            )}
            <Button onPress={handleSubmit} parentStyles={classes.buttonStyles}>
              {LABELS.BUTTON_TEXT}
            </Button>
          </div>
        )}
      />
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  overviewDetails: state.landingScreen.tenantDetails as OverviewDetails,
  tenantDetails: state.landingScreen.tenantDetails,
  otherDetails: state.additionalPersonalDetailScreen,
  myAccountFlag: state.myProfileForm.myAccountFlag,
  additionalProfileEmails: state.additionalPersonalDetailScreen.secondaryApplicantProfiles
    .map((sp) => (sp.personalDetails ? sp.personalDetails.email : undefined))
    .filter((email) => !!email),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateProfileChangesFlag: (isChanged: boolean) => {
    dispatch(MasterProfileActions.updateProfileChangesFlag(isChanged));
  },
  postFormDetails: (
    data: PostPersonalDetailsFormData,
    tenantDetails: OverviewDetails | undefined = {
      email: localStorage.getItem('email') || '',
      title: '',
      firstName: localStorage.getItem('firstname') || '',
      lastName: localStorage.getItem('lastname') || '',
      leaseDurationMonth: undefined,
      leaseDurationYear: undefined,
      noOfPeople: 1,
      noOfPets: 0,
      noOfVehicles: 0,
      selectedDate: undefined,
      otherApplicants: [],
      livingWithOthers: false,
    },
    otherDetails: AdditionalPersonalDetailsScreenState,
    onSubmit: () => void,
    id?: number,
  ) => {
    if (id === undefined) {
      const myDetails = {
        firstname: data.firstName as string,
        lastname: data.lastName as string,
        email: data.email as string,
        title: data.title as string,
      };

      dispatch(LandingScreenActions.postFormDetails({ ...tenantDetails, ...myDetails }));
      dispatch(MyProfileAction.postFormDetails(data));
    } else {
      const newApplicant: OtherApplicantsData = {
        key: id,
        title: data.title as string,
        firstName: data.firstName as string,
        lastName: data.lastName as string,
        email: data.email as string,
        selectedDate: data.selectedDateOfBirth || null,
        isChild: !CalculationHelper.isAbove18(data.selectedDateOfBirth),
        isManaged: true,
        mobile: data.mobile,
        selectedDateOfBirth: data.selectedDateOfBirth,
        emergencyContactName: data.emergencyContactName,
        emergencyContactRelationship: data.emergencyContactRelationship,
        emergencyContactEmail: data.emergencyContactEmail,
        emergencyContactMobile: data.emergencyContactMobile,
      };

      const otherApplicants = {
        otherApplicants: tenantDetails.otherApplicants.find((o) => o.key === newApplicant.key)
          ? tenantDetails.otherApplicants
          : [...tenantDetails.otherApplicants, newApplicant],
      };

      const noOfPeople = {
        noOfPeople: otherApplicants.otherApplicants.length + 1,
      };
      dispatch(
        LandingScreenActions.postFormDetails({
          ...tenantDetails,
          ...otherApplicants,
          ...noOfPeople,
        }),
      );
      if (!CalculationHelper.isAbove18(data.selectedDateOfBirth)) {
        dispatch(AdditionalPersonalDetailsScreenActions.postFormDetails(newApplicant as any));

        otherDetails.secondaryApplicantProfiles.map((tenant, key) => {
          if (tenant.personalDetails) {
            if (newApplicant.key === tenant.personalDetails.key) {
              dispatch(AdditionalPersonalDetailsScreenActions.deleteSeconadaryProfile({ id: key }));
            }
          }
        });
      } else {
        dispatch(AdditionalPersonalDetailsScreenActions.postMyProfileFormDetails(data, id));
        otherDetails.dependentProfiles.map((tenant, key) => {
          if (newApplicant.key === tenant.key) {
            dispatch(AdditionalPersonalDetailsScreenActions.deleteAdditionalProfile({ id: key }));
          }
        });
      }
    }
    onSubmit();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetailsForm);
